// docUtils.js

import JSZip from 'jszip';
import omml2mathml from 'omml2mathml'; // Adjust based on your declaration setup

export const handleWord = async (file) => {
  if (!file) {
    throw new Error('No file selected.');
  }

  // Read the file content as ArrayBuffer
  const reader = new FileReader();
  const arrayBuffer = await new Promise((resolve, reject) => {
    reader.onload = (event) => resolve(event.target?.result);
    reader.onerror = (error) => reject(error);
    reader.readAsArrayBuffer(file);
  });

  try {
    // Unzip the .docx file and read its XML content
    const zip = new JSZip();
    const zipContent = await zip.loadAsync(arrayBuffer);

    // Check if document.xml exists
    const documentXmlFile = zipContent.file('word/document.xml');
    if (!documentXmlFile) {
      throw new Error('document.xml not found in the .docx file.');
    }

    // Read document.xml where the text and equations are stored
    const documentXml = await documentXmlFile.async('text');

    // Parse the XML to extract text and equations
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(documentXml, 'application/xml');

    // Prepare to build the final content string
    let finalContent = '';

    // Function to recursively process nodes
    // Update the processNodes function to use async/await
    const processNodes = async (node) => {
      if (node.nodeType === Node.ELEMENT_NODE) {
        const element = node;
        if (element.tagName === 'w:p') {
          finalContent += `<p>`; //style="${getParagraphStyle(element)}"
        } else if (element.tagName === 'w:r') {
          const vertAlign = element.getElementsByTagNameNS('*', 'vertAlign')[0];
          if (vertAlign) {
            const vertAlignVal = vertAlign.getAttribute('w:val');
            if (vertAlignVal === 'subscript') {
              finalContent += `<sub>`;
            } else if (vertAlignVal === 'superscript') {
              finalContent += `<sup>`;
            }
          } else {
            finalContent += `<span style="${getRunStyle(element)}">`;
          }
        } else if (element.tagName === 'w:t') {
          finalContent += element.textContent || '';
          finalContent += `</span>`;
        } else if (element.tagName === 'w:tbl') {
          finalContent += `<table>`;
          await processTable(element); // Process the table element separately
          finalContent += `</table>`;
          return; // Avoid further processing of this element to prevent duplicates
        } else if (element.tagName === 'm:oMath') {
          // Copy the element to avoid modifying the original
          let elementCopy = element.cloneNode(true);
          // Find all the tag <m:d>,
          // then find the tag <m:e> inside <m:d>,
          // then find the tag <m:r> inside <m:e>, copy that <m:r>, change the content of <m:t> tag inside <m:r> to '(' and ')' then insert the copied <m:r> at the beginning and end of <m:e>
          const mDNodes = elementCopy.getElementsByTagName('m:d');
          for (let i = 0; i < mDNodes.length; i++) {
            const mDNode = mDNodes[i];
            const mENode = mDNode.getElementsByTagName('m:e')[0];
            const mRNode = mENode.getElementsByTagName('m:r')[0];
            const mRNodeCopy1 = mRNode.cloneNode(true);
            const mRNodeCopy2 = mRNode.cloneNode(true);
            mRNodeCopy1.getElementsByTagName('m:t')[0].textContent = '(';
            mRNodeCopy2.getElementsByTagName('m:t')[0].textContent = ')';

            mENode.insertBefore(mRNodeCopy1, mENode.firstChild);

            mENode.appendChild(mRNodeCopy2);
          }

          const mathml = omml2mathml(elementCopy);

          // Check if elementCopy contains any tag <m:dPr>,
          // Then get all the <mfenced> tags inside mathml
          // if correct then the number of <mfenced> tags should be equal to the number of <m:d> tags
          // then check if there are any <m:begChr> and <m:endChr> tags inside <m:dPr> tags
          // if yes, add attribute open and close to <mfenced> tags by their values
          // <m:dPr xmlns:m="http://schemas.openxmlformats.org/officeDocument/2006/math">
          //   <m:begChr m:val="{" />
          //   <m:endChr m:val="" />
          //   <m:ctrlPr>
          //     <w:rPr xmlns:w="http://schemas.openxmlformats.org/wordprocessingml/2006/main">
          //       <w:rFonts w:ascii="Cambria Math" w:hAnsi="Cambria Math" />
          //       <w:i />
          //       <w:sz w:val="26" />
          //       <w:szCs w:val="26" />
          //     </w:rPr>
          //   </m:ctrlPr>
          // </m:dPr>;
          // and remove the pair
          const mDPrNodes = elementCopy.getElementsByTagName('m:dPr');
          const mfencedNodes = mathml.getElementsByTagName('mfenced');
          if (mDPrNodes.length === mfencedNodes.length) {
            for (let i = 0; i < mDPrNodes.length; i++) {
              const mDPrNode = mDPrNodes[i];
              const mfencedNode = mfencedNodes[i];
              const mBegChrNode = mDPrNode.getElementsByTagName('m:begChr')[0];
              const mEndChrNode = mDPrNode.getElementsByTagName('m:endChr')[0];

              if (mBegChrNode && mEndChrNode) {
                const open = mBegChrNode.getAttribute('m:val');
                const close = mEndChrNode.getAttribute('m:val');
                mfencedNode.setAttribute('open', open);
                mfencedNode.setAttribute('close', close || '');
              }

              const mRowNodes = mfencedNode.getElementsByTagName('mrow');

              for (let i = 0; i < mRowNodes.length; i++) {
                const mRowNode = mRowNodes[i];
                if (
                  mRowNode.children[0].tagName.toLowerCase() === 'mo' &&
                  mRowNode.children[0].textContent === '('
                ) {
                  mRowNode.removeChild(mRowNode.children[0]);
                }
                if (
                  mRowNode.children[
                    mRowNode.children.length - 1
                  ].tagName.toLowerCase() === 'mo' &&
                  mRowNode.children[mRowNode.children.length - 1]
                    .textContent === ')'
                ) {
                  mRowNode.removeChild(
                    mRowNode.children[mRowNode.children.length - 1]
                  );
                }
              }
            }
          }

          finalContent += `<span class="math">${mathml.outerHTML}</span>`;
        } else if (element.tagName === 'w:drawing') {
          const imageId = extractImageId(element);
          if (imageId) {
            const imageUrl = await getImageUrl(zipContent, imageId); // Await the image URL fetching
            if (imageUrl) {
              finalContent += `<img src="${imageUrl}" alt="Image" style="max-width: 100%; height: auto;" />`;
            }
          }
        }

        // // Process child nodes recursively
        // await Promise.all(Array.from(element.childNodes).map(processNodes));

        // Process child nodes recursively
        for (const childNode of Array.from(element.childNodes)) {
          await processNodes(childNode); // Await each child node processing
        }

        if (element.tagName === 'w:p') {
          finalContent += `</p>`;
        } else if (element.tagName === 'w:r') {
          const vertAlign = element.getElementsByTagNameNS('*', 'vertAlign')[0];
          if (vertAlign) {
            const vertAlignVal = vertAlign.getAttribute('w:val');
            if (vertAlignVal === 'subscript') {
              finalContent += `</sub>`;
            } else if (vertAlignVal === 'superscript') {
              finalContent += `</sup>`;
            }
          } else {
            finalContent += `</span>`;
          }
        }
      }
    };

    // Function to process table content
    const processTable = async (tableElement) => {
      for (const row of Array.from(tableElement.getElementsByTagName('w:tr'))) {
        finalContent += `<tr>`;
        for (const cell of Array.from(row.getElementsByTagName('w:tc'))) {
          finalContent += `<td>`;
          for (const cellContent of Array.from(cell.childNodes)) {
            await processNodes(cellContent);
          }
          finalContent += `</td>`;
        }
        finalContent += `</tr>`;
      }
    };

    // Helper function to get paragraph style
    const getParagraphStyle = (element) => {
      const alignmentNode = element.getElementsByTagNameNS('*', 'jc')[0]; // Using wildcard for namespace
      const alignment = alignmentNode
        ? alignmentNode.getAttribute('w:val')
        : 'left';
      return `text-align: ${alignment};`;
    };

    // Helper function to get run style
    const getRunStyle = (element) => {
      let styles = '';
      const bold = element.getElementsByTagNameNS('*', 'b')[0];
      const italic = element.getElementsByTagNameNS('*', 'i')[0];
      const underline = element.getElementsByTagNameNS('*', 'u')[0];

      if (bold) styles += 'font-weight: bold; ';
      if (italic) styles += 'font-style: italic; ';
      if (underline) styles += 'text-decoration: underline; ';

      return styles;
    };

    // Helper function to extract the image ID from the drawing element
    const extractImageId = (element) => {
      const blipElement = element.getElementsByTagNameNS('*', 'blip')[0];
      if (blipElement) {
        return (
          blipElement.getAttribute('r:embed') ||
          blipElement.getAttribute('r:link')
        );
      }
      return null;
    };

    // Helper function to get the image URL
    // Helper function to get the image URL
    const getImageUrl = async (zipContent, imageId) => {
      // Load the relationships file
      const relsFile = zipContent.file('word/_rels/document.xml.rels');
      if (!relsFile) {
        throw new Error('document.xml.rels not found in the .docx file.');
      }

      const relsXml = await relsFile.async('text');
      const parser = new DOMParser();
      const relsDoc = parser.parseFromString(relsXml, 'application/xml');

      // Find the relationship element with the matching Id
      const relationship = Array.from(
        relsDoc.getElementsByTagName('Relationship')
      ).find((rel) => rel.getAttribute('Id') === imageId);

      if (!relationship) {
        throw new Error(`Relationship with Id ${imageId} not found.`);
      }

      // Extract the target attribute which contains the image file path
      const target = relationship.getAttribute('Target');
      const imageName = target.split('/').pop(); // Get the image file name

      // Check for multiple image formats
      const jpegFile = zipContent.file(`word/media/${imageName}`);
      const pngFile = zipContent.file(`word/media/${imageName}`);
      const gifFile = zipContent.file(`word/media/${imageName}`);

      // Check and return the first found format
      if (jpegFile) {
        const base64Image = await jpegFile.async('base64');
        return `data:image/jpeg;base64,${base64Image}`;
      } else if (pngFile) {
        const base64Image = await pngFile.async('base64');
        return `data:image/png;base64,${base64Image}`;
      } else if (gifFile) {
        const base64Image = await gifFile.async('base64');
        return `data:image/gif;base64,${base64Image}`;
      }

      return ''; // Return an empty string if no image is found
    };

    const mergeAdjacentSpansWithSameStyles = (htmlContent) => {
      // Parse the HTML content
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlContent, 'text/html');

      // Function to merge adjacent spans with the same styles
      const mergeSpans = (parent) => {
        let i = 0;
        while (i < parent.childNodes.length - 1) {
          const currentNode = parent.childNodes[i];
          const nextNode = parent.childNodes[i + 1];

          if (
            currentNode.nodeType === Node.ELEMENT_NODE &&
            nextNode.nodeType === Node.ELEMENT_NODE &&
            currentNode.tagName === 'SPAN' &&
            nextNode.tagName === 'SPAN' &&
            currentNode.getAttribute('style') === nextNode.getAttribute('style')
          ) {
            // Merge the contents of the adjacent spans
            currentNode.innerHTML += nextNode.innerHTML;
            // Remove the next span
            parent.removeChild(nextNode);
          } else {
            i++;
          }
        }
      };

      // Traverse the DOM tree and merge spans
      const traverseAndMerge = (node) => {
        if (node.nodeType === Node.ELEMENT_NODE) {
          mergeSpans(node);
          node.childNodes.forEach(traverseAndMerge);
        }
      };

      // Start the traversal from the body element
      traverseAndMerge(doc.body);

      // Serialize the DOM tree back to an HTML string
      return doc.body.innerHTML;
    };

    // Start processing from the root element
    await Promise.all(
      Array.from(xmlDoc.documentElement.childNodes).map(processNodes)
    );
    finalContent = mergeAdjacentSpansWithSameStyles(finalContent);
    // console log the final content in nice format
    //console.log('Extracted content:', finalContent.trim()); // Log the final string
    return finalContent.trim(); // Return the final HTML content
  } catch (err) {
    console.error('Error processing DOCX:', err);
    throw err; // Re-throw the error for handling outside
  }
};

// <MenuItem value="Math">Toán</MenuItem>
// <MenuItem value="Physic">Lý</MenuItem>
// <MenuItem value="Chemical">Hoá</MenuItem>

export const translateSubject = (subject) => {
  switch (subject) {
    case 'Math':
      return 'Toán';
    case 'Physic':
      return 'Vật Lý';
    case 'Chemical':
      return 'Hoá học';
    default:
      return subject;
  }
};
