const paths = {
  HOME: '/',
  AUTH: '/auth',
  EXAMMING: '/examming/:examId',
  ANSWER: '/answer/:examId',
  LEADERBOARD: '/leaderboard/:examId',
  BIO: '/bio',
  EXAM_SHEET: '/sheet/:normalizedName',
  ANSWER_SHEET: '/answersheet/:normalizedName',
  COURSE: '/course/:courseId',
  MYINFO: '/myinfo', //MyInfo includes MyCourse, Me and History
  MYCOURSE: '/mycourse',
  ME: '/me',
  HISTORY: '/history',
  SUBCRIPTIONS: '/subscriptions',
  POLICY: '/policy',
};

export default paths;
