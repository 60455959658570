import React from 'react';
import { Box, CardMedia, SxProps } from '@mui/material';

const ImageComponent = ({
  imageUrl,
  title,
  sx,
}: {
  imageUrl: string;
  title: string;
  sx?: SxProps;
}) => {
  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        paddingTop: '56.25%', // 16:9 aspect ratio
        borderRadius: 10,
        ...sx,
      }}
    >
      <img
        src={imageUrl}
        alt={title}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          borderRadius: '8px',
        }}
      />
    </Box>
  );
};

export default ImageComponent;
