import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  TextField,
  Paper,
  Box,
  List,
  Button,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import useAuth from '../../hooks/useAuth';
import InfoItem from '../Courses/CourseInfoItem';
import InfoIcon from '@mui/icons-material/Info';
import { ItemPageProps } from './type';
import { useLocation } from 'react-router-dom';
import {
  addOneToPDFView,
  addOneToVideoView,
  createNewPDF,
  createNewVideo,
  fetchPDFById,
  fetchVideoById,
  updatePDFById,
  updateVideoById,
} from '../../api';
import {
  addCourseItem,
  CourseItem,
  updateCourseItem,
} from '../../redux/slices/courseItemSlice';
import { appActions } from '../../redux/slices/appSlice';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import useResponsive from '../../hooks/useResponsive';

export interface VideoPdfItemInput {
  title: string;
  url: string;
  urlItem: File | null;
  onlyForMembers: boolean;
}

const VideoPdfPage: React.FC<ItemPageProps> = ({
  itemId,
  mode = 'view',
  courseId = '',
  parentFolderId = '',
  closeDialog = () => {},
  type = 'video',
  isTeacher,
  itemTemplate,
}) => {
  const { user } = useAuth();
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false); // Add loading state
  const [loadingButton, setLoadingButton] = useState<boolean>(false); // Add loading state

  const [itemInput, setItemInput] = useState<VideoPdfItemInput>({
    title: 'New Item',
    url: '',
    urlItem: null,
    onlyForMembers: false,
  });

  useEffect(() => {
    if (itemTemplate) {
      setItemInput({
        title: itemTemplate.title,
        url: itemTemplate.url,
        urlItem: null,
        onlyForMembers: false,
      });
    }
  }, [itemTemplate]);

  useEffect(() => {
    const loadItem = async () => {
      try {
        setLoading(true);
        if (!itemId) return;
        let res;
        if (type === 'video') {
          res = await fetchVideoById(itemId);
          await addOneToVideoView(itemId);
        } else if (type === 'pdf') {
          res = await fetchPDFById(itemId);
          await addOneToPDFView(itemId);
        }

        const data = res.data;

        setItemInput({
          title: data.title || 'Untitled',
          url: data.url,
          urlItem: null,
          onlyForMembers: data.onlyForMembers,
        });
      } catch (err) {
        console.error(`Error fetching ${type}:`, err);
        dispatch(
          appActions.showNotification({
            variant: 'error',
            message: `Error fetching ${type}`,
          })
        );
      } finally {
        setLoading(false); // End loading
      }
    };

    loadItem();
  }, [itemId, type, dispatch]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, files } = e.target;
    if (name === 'url' && files && files.length > 0) {
      const file = files[0];
      const fileURL = URL.createObjectURL(file); // Create URL for the file

      setItemInput((prevState) => ({
        ...prevState,
        url: fileURL,
        urlItem: file,
      }));
    } else {
      setItemInput((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSave = async () => {
    const updatedItem: FormData = new FormData();
    updatedItem.append('title', itemInput.title);
    updatedItem.append('url', itemInput.url);
    if (itemInput.urlItem) {
      updatedItem.append('urlItem', itemInput.urlItem);
    }
    updatedItem.append('onlyForMembers', itemInput.onlyForMembers.toString());
    try {
      setLoadingButton(true);
      if (mode === 'edit') {
        // Update item
        if (type === 'video') {
          const videoPdfItem = await updateVideoById(itemId, updatedItem);
          const courseItem = {
            ...videoPdfItem.data,
            type: 'video',
            itemId: videoPdfItem.data.videoId,
          } as CourseItem;
          dispatch(updateCourseItem(courseItem));
        }
        if (type === 'pdf') {
          const videoPdfItem = await updatePDFById(itemId, updatedItem);
          const courseItem = {
            ...videoPdfItem.data,
            type: 'pdf',
            itemId: videoPdfItem.data.pdfId,
          } as CourseItem;
          dispatch(updateCourseItem(courseItem));
        }
      } else {
        updatedItem.append('courseId', courseId);
        updatedItem.append('parentFolderId', parentFolderId);
        // Create new item
        if (type === 'video') {
          const videoPdfItem = await createNewVideo(updatedItem);
          const courseItem = {
            ...videoPdfItem.data,
            type: 'video',
            itemId: videoPdfItem.data.videoId,
          } as CourseItem;
          dispatch(addCourseItem(courseItem));
        }
        if (type === 'pdf') {
          const videoPdfItem = await createNewPDF(updatedItem);
          const courseItem = {
            ...videoPdfItem.data,
            type: 'pdf',
            itemId: videoPdfItem.data.pdfId,
          } as CourseItem;
          dispatch(addCourseItem(courseItem));
        }
      }
    } catch (err) {
      console.error('Error saving item:', err);
      dispatch(
        appActions.showNotification({
          variant: 'error',
          message: 'Error saving item',
        })
      );
    } finally {
      setLoadingButton(false); // End loading
    }
    closeDialog();
  };

  const height =
    location.pathname.includes('video') || location.pathname.includes('pdf')
      ? '80vh'
      : '70vh';

  const isMobile = useResponsive('down', 'md');

  return (
    <Box
      sx={{
        width: '100%',
        p: 3,
        pb: 0,
        height: isMobile ? '100%' : 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        height="100%"
        sx={{
          minHeight: '60vh',
          flexDirection: {
            xs: 'column-reverse',
            md: 'row',
          },
        }}
      >
        {/* Left Panel */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            height: isMobile ? '100%' : 'auto',
            pt: isMobile ? 2 : 0,
          }}
        >
          {loading ? ( // Show loading spinner when loading
            <CircularProgress />
          ) : itemInput.url ? (
            <iframe
              src={itemInput.url}
              style={{ width: '100%', height: '100%', border: 'none' }}
              title={itemInput.title}
            />
          ) : (
            <Typography variant="h6" align="center" color="textSecondsary">
              Chưa chọn file {type === 'video' ? 'video' : 'PDF'}
            </Typography>
          )}
        </Box>

        {/* Right Panel */}
        {mode !== 'view' && (
          <Box>
            <Paper
              elevation={3}
              sx={{
                padding: 2,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 0,
              }}
            >
              <Typography variant="h6" gutterBottom>
                {type.toUpperCase()} Information
              </Typography>

              {isTeacher && (mode === 'edit' || mode === 'create') ? (
                <>
                  {type === 'pdf' && (
                    <Button
                      variant="contained"
                      component="label"
                      sx={{ mb: 2 }}
                    >
                      <PictureAsPdfIcon sx={{ mr: 1 }} />
                      Tải file PDF
                      <input
                        type="file"
                        name="url"
                        accept="application/pdf"
                        onChange={handleInputChange}
                        hidden
                      />
                    </Button>
                  )}
                  {type === 'video' && (
                    <Button
                      variant="contained"
                      component="label"
                      sx={{ mb: 2 }}
                    >
                      <VideoLibraryIcon sx={{ mr: 1 }} />
                      Tải video
                      <input
                        type="file"
                        name="url"
                        accept="video/*"
                        onChange={handleInputChange}
                        hidden
                      />
                    </Button>
                  )}

                  <TextField
                    sx={{ mb: 2 }}
                    label="Title"
                    name="title"
                    value={itemInput.title}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    variant="standard"
                    InputProps={{
                      startAdornment: (
                        <InfoOutlinedIcon style={{ marginRight: 8 }} />
                      ),
                    }}
                  />

                  {/* <FormControlLabel
                    control={
                      <Checkbox
                        checked={itemInput.onlyForMembers}
                        onChange={(e) =>
                          setItemInput((prevState) => ({
                            ...prevState,
                            onlyForMembers: e.target.checked,
                          }))
                        }
                      />
                    }
                    label={
                      <Typography sx={{ fontSize: '14px' }}>
                        {' '}
                        Chỉ dành cho thành viên khoá
                      </Typography>
                    }
                  /> */}
                </>
              ) : (
                <Box>
                  <List>
                    <InfoItem
                      icon={<InfoIcon sx={{ fontSize: 18 }} />}
                      value={itemInput.title}
                    />
                  </List>
                </Box>
              )}
            </Paper>
          </Box>
        )}
      </Box>
      {/* Save Button */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
        {(mode === 'edit' || mode === 'create') && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={
              loadingButton ||
              !itemInput.title ||
              (mode === 'create' && !itemInput.urlItem)
            }
          >
            {loadingButton ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              'Save'
            )}
          </Button>
        )}
        <Button
          variant="outlined"
          color="primary"
          onClick={closeDialog}
          sx={{ ml: 1 }}
        >
          Close
        </Button>
      </Box>
    </Box>
  );
};

export default VideoPdfPage;
