import { light } from '@mui/material/styles/createPalette';

declare module '@mui/material/styles' {
  interface Palette {
    lighter: Palette['primary'];
    digital: Palette['primary'];
    highlightText: Palette['primary'];
    prize: {
      first: string;
      second: string;
      third: string;
      normal: string;
    };
    date: {
      exam: String;
      lesson: String;
    };
    gradient: {
      light: String;
    };
  }
  interface TypeBackground {
    darker: string;
    paperDarker: string;
  }
  // allow configuration using `createTheme`
}
const palette = {
  primary: {
    main: '#fff',
    light: '#2E2E2E',
  },
  secondary: {
    main: '#cccccc',
  },
  text: {
    primary: '#F1F1F1',
    secondary: '#aaa',
    disabled: '#717171',
  },
  lighter: {
    main: '#fff',
  },
  prize: {
    first: '#cd8f00', //feb102 #cd8f00
    second: '#adbece',
    third: '#e36600',
    normal: '#fff',
  },
  background: {
    default: '#0F0F0F',
    paper: '#282828',
    paperDarker: '#fbfbfb',
    darker: '#f1f1f1',
  },
  date: {
    exam: '#5a7f8f',
    lesson: '#fe6c85',
  },
  gradient: {
    light: 'linear-gradient(to right, #FCDC93, #f08ad3 75%)',
  },
  digital: {
    main: '#7ed4bd',
  },
  highlightText: {
    main: '#5A7F8F',
  },
};

export default palette;
