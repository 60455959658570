import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  Container,
  CircularProgress,
  Typography,
  Grid,
  Icon,
  IconButton,
} from '@mui/material';
import Course from './Course'; // Import the Course component
import useAuth from '../../hooks/useAuth';
import CreateCourseForm from './CreateCourseForm';
import { fetchAllCourses, getTop20UpcomingExams } from '../../api'; // Import the fetchAllCourses function
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/index';
import { setCourses } from '../../redux/slices/courseSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import ErrorDisplay from './Error';
import LoadingDisplay from './Loading';
import CourseHeader from './CourseHeader';
import paths from '../../constants/paths';
import NotFoundPage from '../Error/NotFoundPage';
import DescriptionIcon from '@mui/icons-material/Description';
import { Exam } from '../Exams/type';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ExamComponent from './Exam';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import SwiperCore from 'swiper';
import useResponsive from '../../hooks/useResponsive';
// import Slider from '@ant-design/react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';

const HomePage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const dispatch = useDispatch();
  const [showCreateForm, setShowCreateForm] = useState(false);
  const courses = useSelector((state: RootState) => state.course.courses);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [filter, setFilter] = useState<string>('all');
  const [filteredCourses, setFilteredCourses] = useState(courses);
  const [exams, setExams] = useState<Exam[]>([]);
  const [filteredExams, setFilteredExams] = useState<Exam[]>([]);
  // const sliderRef = useRef<HTMLDivElement>(null); // Reference to the slider container
  const [scrollPosition, setScrollPosition] = useState(0);
  const isMobile = useResponsive('down', 'md');

  const isTeacher =
    (location.pathname === paths.MYCOURSE ||
      location.pathname === paths.MYINFO) &&
    !!user &&
    (user.role === 'admin' || user.role === 'teacher');

  // Check if path is /mycourse then filter courses by user id
  useEffect(() => {
    setLoading(true);

    setTimeout(() => {
      if (isTeacher) {
        setFilteredCourses(
          courses.filter((course) => course.createdBy._id === user._id)
        );
      } else {
        setFilteredCourses(courses);
      }
      setLoading(false); // Set loading to false after the delay
    }, 500); // Ensure loading lasts for at least 0.5 seconds
  }, [dispatch, user, location.pathname, courses, isTeacher, exams]);

  useEffect(() => {
    //setLoading(true);
    const loadCourses = async () => {
      try {
        const data = await fetchAllCourses();
        dispatch(setCourses(data)); // Dispatch setCourses action

        const res = await getTop20UpcomingExams();
        const examData = res.data;
        setExams(examData);
        setFilteredExams(examData);
      } catch (err) {
        console.error('Error fetching courses:', err);
        setError('Failed to load courses');
      } finally {
        //setLoading(false);
      }
    };

    loadCourses();
  }, [dispatch]);

  const handleCreateCourse = () => {
    setShowCreateForm(true);
  };

  const handleCloseForm = () => {
    setShowCreateForm(false);
  };

  const handleFilterChange = (newFilter: string) => {
    setFilter(newFilter); // Update the filter state
    setLoading(true); // Set loading to true immediately

    setTimeout(() => {
      let filteredCourse;
      let filteredExam;
      if (newFilter === 'all') {
        filteredCourse = courses; // Show all courses
        filteredExam = exams;
      } else {
        // Filter by subject
        filteredCourse = courses.filter((course) =>
          course.subjects?.includes(newFilter)
        );
        filteredExam = exams.filter((exam) => exam.subject === newFilter);
      }
      // } else if (newFilter === 'free') {
      //   filtered = courses.filter((course) => course.price === 0); // Show free courses
      // } else if (newFilter === 'popular') {
      //   filtered = [...courses].sort((a, b) => b.members - a.members); // Create a copy and sort by most members
      // }

      filteredCourse && setFilteredCourses(filteredCourse); // Update the filtered courses
      filteredExam && setFilteredExams(filteredExam);
      setLoading(false); // Set loading to false after the delay
    }, 500); // Ensure loading lasts for at least 0.5 seconds
  };

  if (
    location.pathname !== paths.HOME &&
    (!user || (user && user.role !== 'admin' && user.role !== 'teacher'))
  ) {
    return <NotFoundPage message="Trang chỉ dành cho giáo viên" />;
  }

  return (
    <Container maxWidth={false}>
      <Box sx={{ position: 'relative', mb: 4, mt: 2 }}>
        <CourseHeader
          onAddCourseClick={handleCreateCourse}
          onFilterChange={handleFilterChange}
          isTeacher={isTeacher}
          filter={filter}
        />

        <CreateCourseForm onClose={handleCloseForm} open={showCreateForm} />

        {loading && (
          <LoadingDisplay loadingMessage="Đang tải chi tiết khóa học..." />
        )}

        {error && (
          <ErrorDisplay errorMessage="Đã xảy ra lỗi. Vui lòng thử lại sau." />
        )}

        {!loading && !error && (
          <>
            {!isTeacher && (
              <Box
                sx={{
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'center',
                  mb: 2,
                }}
              >
                {isMobile ? (
                  <Box
                    sx={{
                      display: 'flex',
                      overflowX: 'auto',
                      scrollBehavior: 'smooth',
                      whiteSpace: 'nowrap',
                      '&::-webkit-scrollbar': {
                        display: 'none',
                      },
                    }}
                  >
                    {filteredExams.map((exam) => (
                      <ExamComponent key={exam.examId} {...exam} />
                    ))}
                  </Box>
                ) : (
                  <Swiper
                    // Calculate the number of slides to show based on screen width / 250
                    slidesPerView={Math.min(
                      Math.floor(window.innerWidth / 250),
                      filteredExams.length
                    )}
                    spaceBetween={0}
                  >
                    {filteredExams.map((exam) => (
                      <SwiperSlide key={exam.examId}>
                        <ExamComponent {...exam} />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                )}
              </Box>
            )}
            <Grid container spacing={3}>
              {filteredCourses.map((course) => (
                <Grid
                  item
                  xs={12} // Full width on extra small screens
                  sm={6} // Half width on small screens and above
                  md={4} // One-third width on medium screens and above (adjust as needed)
                  lg={4} // One-fourth width on large screens and above (adjust as needed)
                  xl={2}
                  key={course.courseId}
                >
                  <Course
                    courseId={course.courseId}
                    image={course.image}
                    title={course.title}
                    description={course.description}
                    favorites={course.favorites}
                    members={course.members}
                    exams={course.exams}
                    videos={course.videos}
                    pdfs={course.pdfs}
                    createdAt={course.createdAt}
                    lastUpdatedAt={course.lastUpdatedAt}
                    createdBy={course.createdBy}
                    price={course.price}
                    contactLink={course.contactLink}
                    isTeacher={isTeacher}
                    rates={course.rates}
                    items={course.items} // Add missing items property
                    learningObjectives={course.learningObjectives} // Add missing learningObjectives property
                  />
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </Box>
    </Container>
  );
};

export default HomePage;
