import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import { useNavigate } from 'react-router-dom';
import paths from '../../constants/paths';

const BottomNavBar: React.FC = () => {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const isStandalone = window.matchMedia('(display-mode: standalone)').matches;

  const handleNavigation = (newValue: number) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        navigate(paths.HOME); // Redirect to "Trang chủ"
        break;
      case 1:
        navigate(paths.MYINFO); // Redirect to "Tôi"
        break;
      case 2:
        navigate(paths.SUBCRIPTIONS); // Redirect to "Khoá đăng ký"
        break;
      default:
        break;
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        position: 'fixed',
        bottom: 0,
        zIndex: 1,
        paddingBottom: isStandalone ? 'env(safe-area-inset-bottom)' : 0,
      }}
    >
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => handleNavigation(newValue)}
        sx={{
          backgroundColor: 'background.paper',
          //height: 56,
          // height: isStandalone ? 84 : 56,
          // alignItems: 'center',
          // pt: 1,
        }}
      >
        <BottomNavigationAction label="Trang chủ" icon={<HomeIcon />} />
        <BottomNavigationAction label="Tôi" icon={<PersonIcon />} />
        <BottomNavigationAction label="Đăng ký" icon={<SubscriptionsIcon />} />
      </BottomNavigation>
      {isStandalone && (
        <Box sx={{ height: 14, backgroundColor: 'background.paper' }} />
      )}
    </Box>
  );
};

export default BottomNavBar;
