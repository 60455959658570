import React, { useState } from 'react';
import {
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  TextField,
  Box,
  IconButton,
  Stack,
} from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PeopleIcon from '@mui/icons-material/People';
import DescriptionIcon from '@mui/icons-material/Description';
import YouTubeIcon from '@mui/icons-material/YouTube';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import InfoItem from './CourseInfoItem';
import LinkIcon from '@mui/icons-material/Link';
import { CircleButton } from '../Home/Course';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import AddIcon from '@mui/icons-material/Add';
import RHFInput from '../../components/RHF/RHFTextField';
import StarRoundedIcon from '@mui/icons-material/StarRounded';

interface CourseInfoSectionProps {
  courseContent: any;
  isTeacher: boolean;
  onAddNewLink: (newLink: string) => void;
  onAddNewContactLink: (newLink: string) => void;
  onDeleteLink: (link: string) => void;
  onAddNewLearningObjective: (newObjective: string) => void;
  onDeleteLearningObjective: (objective: string) => void;
  isDesktop?: boolean;
}

const CourseInfoSection: React.FC<CourseInfoSectionProps> = ({
  courseContent,
  isTeacher,
  onAddNewLink,
  onAddNewContactLink,
  onDeleteLink,
  onAddNewLearningObjective,
  onDeleteLearningObjective,
  isDesktop,
}) => {
  const [isAddingLink, setIsAddingLink] = useState(false);
  const [isAddingContactLink, setIsAddingContactLink] = useState(false);
  const [newLink, setNewLink] = useState('');
  const [isAddingLearningObjective, setIsAddingLearningObjective] =
    useState(false);

  const handleLinkSubmit = () => {
    if (newLink.trim()) {
      onAddNewLink?.(newLink.trim());
    }
    setIsAddingLink(false);
    setNewLink('');
  };

  const handleContactLinkSubmit = () => {
    if (newLink.trim()) {
      onAddNewContactLink?.(newLink.trim());
    }
    setIsAddingContactLink(false);
    setNewLink('');
  };

  const handleLearningObjectiveSubmit = () => {
    if (newLink.trim()) {
      onAddNewLearningObjective?.(newLink.trim());
    }
    setIsAddingLearningObjective(false);
    setNewLink('');
  };

  // Calculate average rating with precision of 1
  const totalRate = courseContent.rates.reduce(
    (total: number, rate: number) => total + rate,
    0
  );

  const averageRate =
    courseContent.rates.length > 0
      ? (totalRate / courseContent.rates.length).toFixed(1)
      : 0;

  return (
    <>
      <Paper
        elevation={isDesktop ? 3 : 0}
        sx={{
          p: 2,
          marginBottom: isDesktop ? 2 : 0,
          backgroundColor: isDesktop ? 'background.paper' : 'transparent',
          px: isDesktop ? 2 : 0,
        }}
      >
        <Typography variant="h6" gutterBottom>
          Những gì bạn sẽ học
        </Typography>

        {courseContent.learningObjectives.map(
          (objective: string, index: number) => (
            <Box
              key={index}
              display="flex"
              justifyContent="space-between"
              mb={0}
            >
              <InfoItem
                icon={<CheckIcon sx={{ fontSize: 18 }} />}
                value={objective}
                sx={{
                  color: 'text.primary',
                  marginBottom: 0,
                  maxWidth: '80%',
                }}
                isWrap={false}
              />
              {isTeacher && (
                <IconButton
                  size="small"
                  color="primary"
                  onClick={() => onDeleteLearningObjective(objective)}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              )}
            </Box>
          )
        )}

        {
          isTeacher && (
            <Box display="flex" justifyContent="space-between" mb={0}>
              {isAddingLearningObjective ? (
                <TextField
                  autoFocus
                  fullWidth
                  variant="standard"
                  value={newLink}
                  onChange={(e) => setNewLink(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') handleLearningObjectiveSubmit();
                    if (e.key === 'Escape') setIsAddingLearningObjective(false);
                  }}
                  onBlur={() => setIsAddingLearningObjective(false)}
                />
              ) : (
                <InfoItem
                  icon={<CheckIcon sx={{ fontSize: 18 }} />}
                  value={'Mục tiêu học tập mới'}
                  sx={{
                    color: 'text.primary',
                    marginBottom: 0,
                    maxWidth: '80%',
                    opacity: 0.2,
                  }}
                  isWrap={false}
                />
              )}
              <IconButton
                size="small"
                color="primary"
                onClick={() => setIsAddingLearningObjective(true)}
              >
                <AddIcon fontSize="small" />
              </IconButton>
            </Box>
          )
          // <Button
          //   variant="text"
          //   color="primary"
          //   onClick={() => setIsAddingLearningObjective(true)}
          //   sx={{ padding: 0, textTransform: 'none', display: 'flex' }}
          // >
          //   + Thêm mục tiêu học tập
          // </Button>
        }
      </Paper>
      <Typography variant="h6" gutterBottom>
        Thông tin khóa học
      </Typography>
      <InfoItem
        icon={<LocalAtmIcon sx={{ fontSize: 18 }} />}
        value={courseContent.price || 'Miễn phí'}
        label={courseContent.price ? 'đ' : ''}
      />

      <InfoItem
        icon={<PeopleIcon sx={{ fontSize: 18 }} />}
        value={courseContent.members}
        label="Thành viên"
      />
      <InfoItem
        icon={<StarRoundedIcon sx={{ fontSize: 18 }} />}
        value={averageRate}
        label={`(${courseContent.rates.length} lượt đánh giá)`}
      />
      {/* {isDesktop && (
        <>
          <CourseInfoItem
            icon={<DescriptionIcon sx={{ fontSize: 18 }} />}
            value={courseContent.exams}
            label="Bài kiểm tra"
          />

          <CourseInfoItem
            icon={<YouTubeIcon sx={{ fontSize: 18 }} />}
            value={courseContent.videos}
            label="Video"
          />
          <CourseInfoItem
            icon={<PictureAsPdfIcon sx={{ fontSize: 18 }} />}
            value={courseContent.pdfs}
            label="PDF"
          />
        </>
      )} */}

      {/* {courseContent.links &&
        courseContent.links.map((link: string, index: number) => (
          <Box key={index} display="flex" justifyContent="space-between" mb={0}>
            <CourseInfoItem
              key={index} // Đảm bảo mỗi mục trong danh sách có một khóa duy nhất
              icon={<LinkIcon color="primary" fontSize="small" />}
              value={link}
              URL={true}
              href={link}
            />
            {isTeacher && (
              <IconButton
                size="small"
                color="primary"
                onClick={() => onDeleteLink(link)}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            )}
          </Box>
        ))} */}
      {courseContent.contactLink && (
        <InfoItem
          icon={<LinkIcon fontSize="small" />}
          value="Liên hệ với giảng viên"
          URL={true}
          href={courseContent.contactLink}
        />
      )}
      {/* {isTeacher &&
        (isAddingLink ? (
          <TextField
            autoFocus
            fullWidth
            placeholder="Nhập liên kết mới"
            value={newLink}
            onChange={(e) => setNewLink(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') handleLinkSubmit();
              if (e.key === 'Escape') setIsAddingLink(false);
            }}
            onBlur={() => setIsAddingLink(false)}
          />
        ) : (
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              setIsAddingLink(true);
            }}
            sx={{ padding: 0, textTransform: 'none', display: 'flex' }}
          >
            + Thêm liên kết mới
          </Button>
        ))} */}

      {/* {isTeacher &&
        (isAddingContactLink ? (
          <TextField
            autoFocus
            fullWidth
            placeholder="Nhập liên kết liên hệ"
            value={newLink}
            onChange={(e) => setNewLink(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') handleContactLinkSubmit();
              if (e.key === 'Escape') setIsAddingContactLink(false);
            }}
            onBlur={() => setIsAddingContactLink(false)}
          />
        ) : (
          <Button
            variant="text"
            color="primary"
            onClick={() => setIsAddingContactLink(true)}
            sx={{ padding: 0, textTransform: 'none', display: 'flex' }}
          >
            + Thay đổi liên kết liên hệ
          </Button>
        ))} */}
    </>
  );
};

export default CourseInfoSection;
