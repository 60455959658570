import React, { useEffect, useState } from 'react';
import { Box, Typography, IconButton, Stack, Slider } from '@mui/material';
import {
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  FormatSize as FormatSizeIcon,
  Fullscreen as FullscreenIcon,
  FullscreenExit as FullscreenExitIcon,
  DarkMode as DarkModeIcon,
  LightMode as LightModeIcon,
  TextSnippet as TextSnippetIcon,
} from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { OutlinedButton } from './AnswerResultPage';
import { appActions } from '../../redux/slices/appSlice';
import { useAppSelector } from '../../hooks/redux';
import useResponsive from '../../hooks/useResponsive';
import useAuth from '../../hooks/useAuth';
import MainButton from '../Courses/MainButton';

interface NavigationBarProps {
  activeQuestion: any;
  questions: any[];
  setActiveQuestion: (question: any) => void;
  handleToggleNavMenu?: () => void;
  setFormatSize: (size: number) => void;
  loadingSubmit?: boolean;
  setShowConfirmSubmitDialog?: (show: boolean) => void;
  showButton?: boolean;
  // navigationString?: string;
}

const NavigationBar: React.FC<NavigationBarProps> = ({
  activeQuestion,
  questions,
  setActiveQuestion,
  handleToggleNavMenu,
  setFormatSize,
  loadingSubmit,
  setShowConfirmSubmitDialog,
  showButton,
  // navigationString,
}) => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const isDarkMode = useAppSelector((state) => state.app.isDarkMode);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const isMobile = useResponsive('down', 'md');
  const isStandalone = window.matchMedia('(display-mode: standalone)').matches;

  // Update fullscreen state when fullscreen changes
  const handleFullscreenChange = () => {
    setIsFullscreen(!!document.fullscreenElement);
  };

  // Set up event listener for fullscreen changes
  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  const toggleFullscreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowRight' || event.key === 'Tab') {
        event.preventDefault();
        // Move to the next question
        if (activeQuestion) {
          const index = questions.indexOf(activeQuestion);
          if (index < questions.length - 1) {
            setActiveQuestion(questions[index + 1]);
          } else {
            handleToggleNavMenu && handleToggleNavMenu();
          }
        }
      } else if (event.key === 'ArrowLeft') {
        event.preventDefault();

        // Move to the previous question
        if (activeQuestion) {
          const index = questions.indexOf(activeQuestion);
          if (index > 0) {
            setActiveQuestion(questions[index - 1]);
          }
        }
      }
    };

    // Attach the keydown event listener when the component is mounted
    window.addEventListener('keydown', handleKeyDown);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [activeQuestion, questions, handleToggleNavMenu, setActiveQuestion]);

  return (
    <Box
      sx={{
        position: 'absolute',
        height: isStandalone ? 56 : 40,
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          px: 1,
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent={isMobile ? 'center' : 'space-between'}
          alignItems="center"
          flexGrow={1}
        >
          {showButton && isMobile ? (
            <MainButton
              variant="first"
              text="Nộp bài"
              icon={<TextSnippetIcon />}
              isLoading={loadingSubmit || false}
              onClick={() => {
                setShowConfirmSubmitDialog && setShowConfirmSubmitDialog(true);
              }}
              sx={{ width: '100%', borderRadius: 4, mx: 3 }}
            />
          ) : (
            <>
              <OutlinedButton
                icon={<KeyboardArrowLeftIcon />}
                onClick={() => {
                  if (activeQuestion) {
                    const index = questions.indexOf(activeQuestion);
                    if (index > 0) {
                      setActiveQuestion(questions[index - 1]);
                    }
                  }
                }}
              />
              <Typography variant="subtitle2">
                {activeQuestion ? questions.indexOf(activeQuestion) + 1 : 0} /{' '}
                {questions.length}
              </Typography>
              <OutlinedButton
                icon={<KeyboardArrowRightIcon />}
                onClick={() => {
                  if (activeQuestion) {
                    const index = questions.indexOf(activeQuestion);
                    if (index < questions.length - 1) {
                      setActiveQuestion(questions[index + 1]);
                    } else {
                      handleToggleNavMenu && handleToggleNavMenu();
                    }
                  }
                }}
              />
            </>
          )}
        </Box>

        {/* Iconbutton for toggle darkmode lightmode */}
        <IconButton
          onClick={() => {
            dispatch(appActions.toggleDarkMode());
          }}
          sx={{ color: 'prize.first' }}
        >
          {isDarkMode ? <LightModeIcon /> : <DarkModeIcon />}
        </IconButton>

        {/* Slider for format size */}
        <Stack
          sx={{ width: 100, mx: 1 }}
          spacing={1}
          direction="row"
          alignItems="center"
        >
          <FormatSizeIcon fontSize="small" />
          <Slider
            defaultValue={isMobile ? 0.8 : 1}
            step={0.1}
            min={0.8}
            max={1.4}
            size="small"
            onChange={(event, value) => {
              if (typeof value === 'number') {
                setFormatSize(value);
              }
            }}
          />
        </Stack>

        {!isMobile && (
          <Box>
            <IconButton onClick={toggleFullscreen} color="primary">
              {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </IconButton>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default NavigationBar;
