import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Rating,
  useTheme,
} from '@mui/material';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import { useDispatch } from 'react-redux';
import { appActions } from '../../redux/slices/appSlice';
import MainButton from './MainButton';
import { PersonAddAltOutlined, Person } from '@mui/icons-material';
import {
  addBuyCourse,
  addRate,
  removeBuyCourse,
  removRate as removeRate,
} from '../../api';
import {
  beCourseMember,
  rateCourse,
  unBeCourseMember,
  unRateCourse,
} from '../../redux/slices/courseItemSlice';
import { StudentInfo } from '../../model/Student';
import { authActions } from '../../redux/slices/authSlice';
import { CourseProps } from '../Home/Course';

type CourseActionsProps = {
  user: StudentInfo | null;
  courseId: string;
  course: CourseProps;
  contactLink?: string;
  price: number;
  isMember: boolean;
  setIsMember: React.Dispatch<React.SetStateAction<boolean>>;
  rate: number;
  setRate: React.Dispatch<React.SetStateAction<number>>;
};

const CourseActions: React.FC<CourseActionsProps> = ({
  user,
  courseId,
  contactLink,
  price,
  isMember,
  setIsMember,
  rate,
  setRate,
  course,
}) => {
  const dispatch = useDispatch();
  const [ratingValue, setRatingValue] = useState<number | null>(5); // Rating value
  const [isDialogOpen, setIsDialogOpen] = useState(false); // State for dialog visibility
  const [isBuyLoading, setIsBuyLoading] = useState(false);

  // Open the rating dialog
  const onRateClick = async () => {
    if (!user || !user._id) {
      dispatch(
        appActions.showNotification({
          variant: 'error',
          message: 'Bạn cần đăng nhập để đánh giá khóa học',
        })
      );
      return;
    }

    if (rate !== -1) {
      await removeRate(user._id, courseId);
      dispatch(unRateCourse(rate));
      dispatch(
        appActions.showNotification({
          message: 'Đã xóa đánh giá của bạn',
        })
      );
      setRate(-1); // Set rated state to false
    } else {
      setIsDialogOpen(true); // Open the dialog when the button is clicked
    }
  };

  // Handle the submission of the rating
  const handleSubmitRating = async () => {
    if (!user || !user._id) {
      dispatch(
        appActions.showNotification({
          variant: 'error',
          message: 'Bạn cần đăng nhập để mua khóa học',
        })
      );
      setIsBuyLoading(false);
      return;
    }
    if (ratingValue === null) {
      dispatch(
        appActions.showNotification({
          variant: 'error',
          message: 'Vui lòng chọn đánh giá trước khi nộp',
        })
      );
      return;
    }
    await addRate(user._id, courseId, ratingValue);
    dispatch(rateCourse(ratingValue));
    // Submit the rating logic (replace this with your API logic)
    dispatch(
      appActions.showNotification({
        message: `Cảm ơn bạn đã đánh giá`,
      })
    );

    setRate(ratingValue); // Set rated state to true
    setIsDialogOpen(false); // Close the dialog
  };

  // Handle buying course logic
  const onBuyCourseClick = async () => {
    setIsBuyLoading(true); // Start loading for buy course button
    if (!user || !user._id) {
      dispatch(
        appActions.showNotification({
          variant: 'error',
          message: 'Bạn cần đăng nhập để mua khóa học',
        })
      );
      setIsBuyLoading(false);
      return;
    }

    try {
      if (!isMember) {
        if (price > 0) {
          dispatch(
            appActions.showLinkNotification({
              message: 'Liên hệ với giảng viên để mua khóa học: ',
              link: contactLink || '',
            })
          );
          setIsBuyLoading(false);
          return;
        } else {
          await addBuyCourse(user._id, courseId);
          dispatch(beCourseMember());
          setIsMember(true);
          dispatch(
            appActions.showNotification({
              message: 'Đã theo dõi khóa học',
            })
          );
          dispatch(authActions.addNewCourseToUserBuyCourses(course));
        }
      } else {
        if (price > 0) {
          dispatch(
            appActions.showNotification({
              variant: 'error',
              message: 'Bạn đã mua khóa học này rồi',
            })
          );
          setIsBuyLoading(false);
        } else {
          await removeBuyCourse(user._id, courseId);
          dispatch(unBeCourseMember());
          dispatch(
            appActions.showNotification({
              message: 'Đã bỏ theo dõi khóa học',
            })
          );
          setIsMember(false);
          dispatch(authActions.removeCourseFromUserBuyCourses(course));
        }
      }
    } catch (err) {
      console.error('Error updating course membership:', err);
    } finally {
      setTimeout(() => setIsBuyLoading(false), 500); // Delay for at least 0.5 seconds
    }
  };

  const theme = useTheme();

  return (
    <Box mt={2}>
      {/* Rating Button */}
      <Button
        fullWidth
        onClick={onRateClick}
        startIcon={rate > -1 ? <StarRoundedIcon /> : <StarBorderRoundedIcon />}
        variant="outlined"
        sx={{
          mb: 1,
        }}
      >
        {rate > -1 ? 'Đã đánh giá' : 'Đánh giá'}
      </Button>

      {/* Dialog for rating */}
      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <DialogTitle>Đánh giá khóa học</DialogTitle>
        <DialogContent sx={{ py: 0 }}>
          <Box textAlign="center">
            <Rating
              value={ratingValue}
              onChange={(event, newValue) => {
                setRatingValue(newValue);
              }}
              size="large"
              icon={<StarRoundedIcon fontSize="inherit" />}
              emptyIcon={<StarBorderRoundedIcon fontSize="inherit" />}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)} color="secondary">
            Hủy
          </Button>
          <Button onClick={handleSubmitRating} variant="contained">
            Gửi
          </Button>
        </DialogActions>
      </Dialog>

      {/* Main Button for buying course */}
      <MainButton
        text={
          price === 0
            ? isMember
              ? 'Bỏ theo dõi khoá'
              : 'Theo dõi khoá'
            : isMember
            ? 'Đã mua khoá'
            : 'Mua khóa học'
        }
        isLoading={isBuyLoading}
        onClick={onBuyCourseClick}
        icon={isMember ? <Person /> : <PersonAddAltOutlined />}
        variant="first"
      />
    </Box>
  );
};

export default CourseActions;
