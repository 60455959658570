import React, { useState, useEffect } from 'react';
import {
  Button,
  TextField,
  Box,
  Typography,
  IconButton,
  Stack,
  CircularProgress,
  Dialog,
  Paper,
  Avatar,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';
import { Cancel } from '@mui/icons-material';
import * as yup from 'yup'; // Import yup
import { yupResolver } from '@hookform/resolvers/yup'; // Import yupResolver
import { useForm, SubmitHandler } from 'react-hook-form'; // Import useForm
import FormProvider from '../../components/RHF/FormProvider'; // Import FormProvider
import RHFTextField from '../../components/RHF/RHFTextField'; // Import RHFTextField
import { addCourse, updateCourseById } from '../../api';
import { useDispatch } from 'react-redux';
import { addCourseAction, updateCourse } from '../../redux/slices/courseSlice';
import useAuth from '../../hooks/useAuth';
import CloseIcon from '@mui/icons-material/Close';
import ImageComponent from './ImageComponent';
import { appActions } from '../../redux/slices/appSlice';

import SearchIcon from '@mui/icons-material/Search';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { makeTeacher, findEmails } from '../../api';

// Extend yup with asynchronous validation for unique courseId
// yup.addMethod(yup.string, 'uniqueCourseId', function (message) {
//   return this.test('uniqueCourseId', message, async function (value) {
//     const { path, createError } = this;
//     if (!value) return true; // Skip if no value yet

//     try {
//       const exists = await checkCourseIdExists(value);
//       if (exists) {
//         return createError({ path, message });
//       }
//       return true;
//     } catch (error) {
//       return createError({ path, message: 'Unable to validate courseId' });
//     }
//   });
// });

// declare module 'yup' {
//   interface StringSchema {
//     uniqueCourseId(message: string): StringSchema;
//   }
// }

interface CourseFormData {
  courseId: string;
  title: string;
  description: string;
  price: string;
  contactLink: string;
  image?: File | null;
}

interface CreateCourseFormProps {
  onClose: () => void;
  open: boolean;
  courseToEdit?: {
    courseId: string;
    title: string;
    description: string;
    image: string;
    price: number;
    contactLink: string;
  };
}

const CreateCourseForm: React.FC<CreateCourseFormProps> = ({
  onClose,
  open,
  courseToEdit,
}) => {
  const dispatch = useDispatch();
  const { user } = useAuth();

  // Yup schema for form validation
  const schema = yup.object().shape({
    courseId: yup.string().required('Mã Khóa Học là bắt buộc'),
    title: yup.string().required('Tên Khóa Học là bắt buộc'),
    description: yup.string().required('Mô tả là bắt buộc'),
    price: yup
      .string()
      .matches(/^(Free|[0-9]+)$/, 'Giá phải là Free hoặc số')
      .required('Giá là bắt buộc'),
    contactLink: yup
      .string()
      .url('Link liên hệ không hợp lệ')
      .required('Link liên hệ là bắt buộc'),
  });

  // Initialize react-hook-form
  const methods = useForm<CourseFormData>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      courseId: courseToEdit?.courseId || '',
      title: courseToEdit?.title || '',
      description: courseToEdit?.description || '',
      price: courseToEdit?.price?.toString() || 'Free',
      contactLink: courseToEdit?.contactLink || '',
    },
  });

  const [imageUrl, setImageUrl] = useState<string>(courseToEdit?.image || '');
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');
  const [users, setUsers] = useState<any[]>([]); // State to store searched users
  const [selectedUser, setSelectedUser] = useState<string | null>(null); // Store the selected user's ID

  // Handle search input changes
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    if (courseToEdit) {
      setImageUrl(courseToEdit.image);
    }
  }, [courseToEdit]);

  // Handle image upload
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setImageFile(file);
      setImageUrl(URL.createObjectURL(file));
    }
  };

  // Fetch users from API based on the search term
  useEffect(() => {
    const fetchUsers = async () => {
      if (searchTerm.length > 2) {
        setLoading(true);
        try {
          const response = await findEmails(searchTerm); // Replace with your token logic
          setUsers(response.data.students); // Assuming the API returns an array of users
        } catch (error) {
          console.error('Error fetching users:', error);
        } finally {
          setLoading(false);
        }
      } else {
        setUsers([]);
      }
    };

    fetchUsers();
  }, [searchTerm]);

  const handleSelectUser = (id: string) => {
    setSelectedUser(id);
  };

  const handleSubmitForm: SubmitHandler<CourseFormData> = async (data) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('courseId', data.courseId.toUpperCase());
      formData.append('title', data.title);
      formData.append('description', data.description);
      formData.append('price', data.price === 'Free' ? '0' : data.price);
      formData.append('contactLink', data.contactLink);
      formData.append('createdBy', selectedUser || user?._id || '');
      if (imageFile) {
        formData.append('image', imageFile);
      }

      if (courseToEdit) {
        const newCourse = await updateCourseById(data.courseId, formData);
        dispatch(updateCourse({ ...newCourse, createdBy: user }));
      } else {
        // Check if adding new course is successful, if response is 400, show error
        const newCourse = await addCourse(formData);
        dispatch(addCourseAction({ ...newCourse, createdBy: user }));
      }
      onClose();
    } catch (error) {
      console.error('Error saving course:', error);
      dispatch(
        appActions.showNotification({
          variant: 'error',
          message: 'Mã khóa học đã tồn tại',
        })
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Paper
        sx={{
          padding: 3,
          maxWidth: 360,
        }}
      >
        <Typography variant="h3" component="h2" textAlign="center">
          {courseToEdit ? 'Sửa Khóa Học' : 'Tạo Khóa Học Mới'}
        </Typography>

        <FormProvider methods={methods} handler={handleSubmitForm}>
          <RHFTextField
            name="courseId"
            label="Mã Khóa Học"
            inputProps={{ style: { textTransform: 'uppercase' } }}
            disabled={!!courseToEdit}
          />
          <RHFTextField name="title" label="Tên Khóa Học" />
          <RHFTextField name="description" label="Mô Tả" />
          {/* <RHFTextField name="price" label="Giá" /> */}
          <RHFTextField
            name="contactLink"
            label="Link liên hệ"
            sx={{ mb: 2 }}
          />

          <Stack direction="column" sx={{ mb: 2, textAlign: 'center' }}>
            {imageUrl && (
              <Box
                sx={{
                  position: 'relative',
                  width: '100%',
                  maxHeight: '150px',
                  overflow: 'hidden',
                }}
              >
                <ImageComponent imageUrl={imageUrl} title="Đã chọn" />
                <IconButton
                  onClick={() => setImageUrl('')}
                  sx={{ position: 'absolute', top: 0, right: 0 }}
                >
                  <Cancel />
                </IconButton>
              </Box>
            )}
            <Button variant="outlined" component="label">
              Chọn Ảnh
              <input
                hidden
                accept="image/*"
                type="file"
                onChange={handleImageChange}
              />
            </Button>
          </Stack>
          <IconButton
            aria-label="close"
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'text.primary',
            }}
            onClick={onClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
          <Stack alignItems="center">
            <Button
              variant="contained"
              type="submit"
              sx={{ width: '100%', mb: 2 }}
            >
              {loading ? (
                <CircularProgress size={24} />
              ) : courseToEdit ? (
                'Cập nhật'
              ) : (
                'Tạo khóa học'
              )}
            </Button>
          </Stack>
        </FormProvider>

        {user?.role === 'admin' && (
          <>
            {/* Search bar */}
            <TextField
              fullWidth
              placeholder="Tìm kiếm email"
              value={searchTerm}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />

            {/* Users List */}
            <List sx={{ mt: 2, maxHeight: 300, overflowY: 'auto' }}>
              {loading ? (
                <CircularProgress />
              ) : users.length === 0 && searchTerm.length > 2 ? (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ textAlign: 'center' }}
                >
                  Không tìm thấy kết quả.
                </Typography>
              ) : (
                users.map((user) => (
                  <ListItem
                    key={user.email}
                    onClick={() => handleSelectUser(user._id)}
                    button
                    selected={selectedUser === user._id}
                  >
                    <ListItemAvatar>
                      {/* <Avatar src={user.avatar}>
                    <PersonOutlinedIcon />
                  </Avatar> */}
                      <img
                        src={user.avatar}
                        alt="avatar"
                        style={{ height: 40, width: 40, borderRadius: '50%' }}
                        referrerPolicy="no-referrer"
                      />
                    </ListItemAvatar>
                    <ListItemText primary={user.name} secondary={user.email} />
                  </ListItem>
                ))
              )}
            </List>
          </>
        )}
      </Paper>
    </Dialog>
  );
};

export default CreateCourseForm;
