import { useEffect, useState } from 'react';
import './App.css';
import MyRouter from './routes';
import { getStudent } from './api';
import { useAppDispatch } from './hooks/redux';
import { authActions } from './redux/slices/authSlice';
import { fetchTimeDiff } from './redux/slices/appSlice';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
  Stack,
} from '@mui/material';
import '@fontsource/open-sans'; // Defaults to weight 400
import '@fontsource/lato'; // Defaults to regular weight (400)
import '@fontsource/oswald/500.css'; // Defaults to regular weight (400)
import '@fontsource/freeman/400.css'; // Defaults to regular weight (400)
import '@fontsource/montserrat/400.css'; // Regular weight
import '@fontsource/montserrat/500.css'; // Medium weight
import '@fontsource/montserrat/600.css'; // Semi-Bold weight
import '@fontsource/montserrat/700.css'; // Bold weight
import '@fontsource/raleway/300.css'; // Medium weight
import '@fontsource/raleway/400.css'; // Medium weight
import '@fontsource/raleway/500.css'; // Medium weight
import '@fontsource/raleway/600.css'; // Semi-Bold weight
import '@fontsource/raleway/700.css'; // Regular weight
import '@fontsource/raleway/800.css'; // Bold weight
import '@fontsource/cormorant-sc/700.css';
import MainButton from './pages/Courses/MainButton';
import useResponsive from './hooks/useResponsive';

function App() {
  const dispatch = useAppDispatch();
  // const isMobile = useResponsive('down', 'md');
  // const [isStandalone, setIsStandalone] = useState(false);
  // const [showDialog, setShowDialog] = useState(false);
  // const [deferredPrompt, setDeferredPrompt] = useState<any>(null);

  // useEffect(() => {
  //   const standaloneDetected = window.matchMedia(
  //     '(display-mode: standalone)'
  //   ).matches;
  //   setIsStandalone(standaloneDetected);

  //   if (!standaloneDetected) {
  //     setShowDialog(true);
  //   }

  //   // Listen for the beforeinstallprompt event
  //   window.addEventListener('beforeinstallprompt', (e) => {
  //     // Prevent the mini info bar from appearing on mobile
  //     //e.preventDefault();
  //     // Stash the event so it can be triggered later
  //     setDeferredPrompt(e);
  //     // Show your custom dialog here if needed
  //     // Example: setShowDialog(true);
  //   });

  //   return () => {
  //     // Clean up event listener
  //     window.removeEventListener('beforeinstallprompt', (e) => {
  //       e.preventDefault();
  //     });
  //   };
  // }, []);

  // const handleOpenInApp = async () => {
  //   if (deferredPrompt) {
  //     // Show the install prompt
  //     deferredPrompt.prompt();
  //     const { outcome } = await deferredPrompt.userChoice;
  //     if (outcome === 'accepted') {
  //       console.log('User accepted the A2HS prompt');
  //     } else {
  //       console.log('User dismissed the A2HS prompt');
  //     }
  //     setDeferredPrompt(null);
  //   }
  //   setShowDialog(false);
  // };

  // const handleCloseDialog = () => {
  //   setShowDialog(false);
  // };

  useEffect(() => {
    const fetchUser = async () => {
      const storedUserData = localStorage.getItem('userId');
      const storedToken = localStorage.getItem('token');
      if (storedUserData !== null && storedToken !== null) {
        const id = storedUserData;
        try {
          const { data: response } = await getStudent(id, storedToken);
          dispatch(authActions.setUser(response));
        } catch (err) {
          console.log(err);
        }
      }
    };

    fetchUser();
    dispatch(fetchTimeDiff());
  }, [dispatch]);
  return (
    <>
      <MyRouter />
      {/* {showDialog && !isStandalone && isMobile && (
        <Dialog open={showDialog} onClose={handleCloseDialog}>
          <Stack alignItems="center" padding={2}>
            <Typography
              m={3}
              mx={5}
              variant="h6"
              component="div"
              sx={{ textAlign: 'center' }}
            >
              Vì trải nghiệm tốt nhất, vui lòng mở ứng dụng trong app Eduzy
            </Typography>

            <MainButton
              variant="first"
              text="Lưu Eduzy"
              onClick={handleOpenInApp}
              sx={{ width: 200 }}
              isLoading={false}
            />
          </Stack>
        </Dialog>
      )} */}
    </>
  );
}

export default App;
