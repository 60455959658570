import React from 'react';
import Box from '@mui/material/Box';
import { GoogleLogin, googleLogout } from '@react-oauth/google';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { appActions } from '../../redux/slices/appSlice';
import { authActions } from '../../redux/slices/authSlice';
import { LoginData } from '../../model/Student';
import { Button, Typography } from '@mui/material';
import { login } from '../../api';
import MESSAGE from '../../constants/message';
import { jwtDecode } from 'jwt-decode';

interface Props {
  isMobile: boolean | null;
}

const LoginDialog: React.FC<Props> = ({ isMobile }) => {
  const dispatch = useAppDispatch();
  const isDarkMode = useAppSelector((state) => state.app.isDarkMode);
  const handleLogin = async (data: LoginData) => {
    try {
      const { data: response } = await login(data);
      dispatch(
        appActions.showNotification({
          variant: 'success',
          message: 'Đăng nhập thành công',
        })
      );
      dispatch(
        authActions.setUserWithToken({
          user: response.data.user,
          token: response.token,
          tokenExpires: response.tokenExpires,
        })
      );
      dispatch(appActions.toggleShowLoginModal());
    } catch (err) {
      dispatch(
        appActions.showNotification({
          variant: 'error',
          message: MESSAGE.WRONG_AUTH_INFO,
        })
      );
    }
  };
  return (
    <Box
      sx={{
        flexGrow: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        p: 3,
      }}
    >
      <Typography variant="h3" sx={{ flexGrow: 1, mb: 3 }}>
        Đăng nhập
      </Typography>
      <GoogleLogin
        onSuccess={(credentialResponse) => {
          if (!credentialResponse.credential) {
            dispatch(
              appActions.showNotification({
                variant: 'error',
                message: 'Đăng nhập thất bại',
              })
            );
            googleLogout();
            return;
          }

          const decodedResponse: any = jwtDecode(credentialResponse.credential);

          handleLogin({
            name: decodedResponse.name,
            email: decodedResponse.email,
            avatar: decodedResponse.picture,
            token: credentialResponse.credential,
          } as LoginData);
        }}
        onError={() => {
          dispatch(
            appActions.showNotification({
              variant: 'error',
              message: 'Đăng nhập thất bại',
            })
          );
        }}
        shape="pill"
        theme={isDarkMode ? 'filled_black' : 'outline'}
        locale="vi"
        //useOneTap
      />
    </Box>
  );
};

export default LoginDialog;
