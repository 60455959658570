import React from 'react';
import { Box, IconButton, Snackbar, SxProps, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

type CourseInfoItemProps = {
  icon: React.ReactNode;
  value: string | number;
  label?: string;
  URL?: boolean; // New prop to determine if value should be a link
  href?: string;
  sx?: SxProps;
  ml?: number;
  variant?: 'subtitle1' | 'subtitle2';
  isWrap?: boolean;
};

const InfoItem: React.FC<CourseInfoItemProps> = ({
  icon,
  value,
  label,
  URL = false, // Default to false
  href = '',
  sx,
  ml = 1,
  variant = 'subtitle2',
  isWrap = true,
}) => {
  const [open, setOpen] = React.useState(false);

  const handleCopy = () => {
    if (href) {
      // Check if the href is already a full link
      const isFullLink =
        href.startsWith('http://') || href.startsWith('https://');

      // If not a full link, add the origin to create the full link
      const fullLink = isFullLink ? href : `${window.location.origin}${href}`;

      // Copy the full link to clipboard
      navigator.clipboard.writeText(fullLink);

      // Open the notification (Snackbar) to show a copy success message
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="row"
      sx={{ maxWidth: '100%', mb: 1, ...sx }}
    >
      {icon}
      {URL ? (
        <>
          <Typography
            variant={variant}
            ml={ml}
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              flex: 1,
              textDecoration: 'underline', // Underline to indicate a link
              color: 'text.primary', // Change color to primary
              transition: 'all 0.3s ease', // Smooth transition effect
              '&:hover': { fontSize: '0.95rem' }, // Change color on hover
            }}
            component="a"
            href={href}
            target="_blank" // Open in a new tab
            rel="noopener noreferrer" // Security measure
            // Create hover effect that increases size slightly
          >
            {value} {label || ''}
          </Typography>
          {/* Add copy icon button */}
          <IconButton
            aria-label="copy-link"
            onClick={handleCopy}
            size="small"
            sx={{ ml: 1, p: 0 }}
          >
            <ContentCopyIcon sx={{ color: 'text.primary', fontSize: 16 }} />
          </IconButton>
        </>
      ) : (
        <Typography
          variant={variant}
          ml={ml}
          sx={
            isWrap
              ? {
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  flex: 1,
                }
              : { whiteSpace: 'normal' }
          }
        >
          {value} {label || ''}
        </Typography>
      )}
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message="Link đã được sao chép"
      />
    </Box>
  );
};

export default InfoItem;
