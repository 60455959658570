import React from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

type ErrorDisplayProps = {
  errorMessage?: string; // Optional prop for custom error messages
  onRetry?: () => void; // Optional prop for retry function
};

const ErrorDisplay: React.FC<ErrorDisplayProps> = ({
  errorMessage = 'Đã xảy ra lỗi. Vui lòng thử lại sau.', // Default message
  onRetry,
}) => {
  const navigate = useNavigate();

  return (
    <Container
      maxWidth="md"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <Box textAlign="center">
        <Typography variant="h6" color="error" sx={{ marginBottom: 2 }}>
          Lỗi
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          {errorMessage}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={onRetry ? onRetry : () => navigate(0)} // Retry function or page refresh
        >
          Thử lại
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => navigate('/')}
          sx={{ marginLeft: 2 }}
        >
          Đi đến Trang chủ
        </Button>
      </Box>
    </Container>
  );
};

export default ErrorDisplay;
