import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  DialogActions,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useResponsive from '../../hooks/useResponsive';

interface DialogFormProps {
  open: boolean;
  onClose: () => void;
  title: string;
  url: string;
  content?: React.ReactNode; // Add this line
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  type?: 'subfolder' | 'other';
}

const DialogForm: React.FC<DialogFormProps> = ({
  open,
  onClose,
  title,
  url,
  content, // Add this line
  maxWidth = 'xl',
  type = 'other',
}) => {
  const isMobile = useResponsive('down', 'md');
  return (
    <Dialog
      fullScreen={type === 'subfolder' ? false : isMobile || false}
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth={maxWidth}
      PaperProps={{ sx: { borderRadius: '0' } }}
    >
      <DialogContent
        sx={{
          px: 0,
          pt: 0,
          scrollbarWidth: 'none', // Firefox
          msOverflowStyle: 'none', // IE and Edge
          '&::-webkit-scrollbar': {
            display: isMobile ? 'none' : 'none', // Webkit
          },
        }}
      >
        {content ? (
          content
        ) : (
          <iframe
            src={url}
            style={{ width: '100%', height: '80vh', border: 'none' }}
            title={title}
          />
        )}
      </DialogContent>
      {/* <DialogActions>
      <Button variant="outlined" onClick={onClose}>
        Close
      </Button>
    </DialogActions> */}
    </Dialog>
  );
};

export default DialogForm;
