import React from 'react';
import { Dialog, Stack, Typography, Button, Box } from '@mui/material';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { useAppDispatch, useAppSelector } from '../hooks/redux';
import { appActions } from '../redux/slices/appSlice';

const LinkNotification = () => {
  const state = useAppSelector((state) => state.app.linkNotification);
  const dispatch = useAppDispatch();
  const handleClose = () => {
    dispatch(appActions.hideLinkNotification());
  };

  // Replace this with your Facebook fanpage link
  const contactLink = state.link || 'https://www.facebook.com/your-fanpage';

  const messageWithLineBreaks = state.message.split('\n').map((line, index) => (
    <div key={index}>
      {line}
      {index < state.message.split('\n').length - 1 && <br />}
    </div>
  ));

  return (
    <Dialog open={state.open} onClose={handleClose}>
      <Stack alignItems="center" padding={2}>
        <ErrorOutlineOutlinedIcon
          sx={{ color: '#FE6C85', width: 50, height: 50 }}
        />
        <Typography
          m={3}
          mx={5}
          variant="h4"
          fontFamily="_SegoeUINormal"
          component="div"
          sx={{ textAlign: 'center' }}
        >
          {messageWithLineBreaks}
        </Typography>

        {/* Clickable link to your fan page */}
        <Typography component="div" sx={{ marginBottom: 2 }}>
          <a href={contactLink} target="_blank" rel="noopener noreferrer">
            {contactLink}
          </a>
        </Typography>

        <Button variant="contained" sx={{ width: 120 }} onClick={handleClose}>
          OK
        </Button>
      </Stack>
    </Dialog>
  );
};

export default LinkNotification;
