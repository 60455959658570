import React from 'react';
import { Box, Typography, Grid, Button, useTheme } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { Question, Result, SectionType } from './type';
import MainButton from '../Courses/MainButton';

interface QuestionListProps {
  sections: SectionType[];
  isMobile: boolean;
  activeQuestion: Question | null;
  check: boolean;
  showSingleQuestion: (question: Question) => void;
  result?: Result;
}

// Helper function to get and format the answer from the result
const getFormattedAnswer = (question: Question, result: Result | undefined) => {
  const answerFound = result?.answers.find(
    (answer) => answer.questionId === question.questionId
  );

  const userAnswer = answerFound
    ? (answerFound?.answer as string)
    : question.correctAnswer;

  if (question.type === 'mctf' && userAnswer) {
    return userAnswer
      .split('') // Split the answer into individual characters
      .map((char) => (char === '1' ? 'Đ' : char === '0' ? 'S' : '_')) // Map '1' to 'Đ', '0' to 'S'
      .join(''); // Join the array back into a string
  }

  return userAnswer || ''; // Return the formatted answer or empty string
};

// Function to format the answer
const formatAnswer = (
  questionIndex: number,
  question: Question,
  result: Result | undefined,
  check: boolean
) => {
  if (!check) return `${questionIndex + 1}. `; // Return the question number only if not checking
  const formattedAnswer = getFormattedAnswer(question, result);
  return `${questionIndex + 1}. ${formattedAnswer}`;
};

// Function to check if the answer is correct
const checkCorrect = (question: Question, result: Result | undefined) => {
  if (!result) return 0;
  const answerFound = result?.answers.find(
    (answer) => answer.questionId === question.questionId
  );

  if (answerFound && !answerFound.check) {
    return 0;
  }

  const userAnswer = answerFound
    ? (answerFound?.answer as string)
    : question.correctAnswer;

  //if (userAnswer.length === 0) return 0; // No answer provided

  const correctAnswer = question.correctAnswer;

  return userAnswer === correctAnswer ? 1 : -1; // 1 for correct, -1 for incorrect
};

const QuestionButtonList: React.FC<QuestionListProps> = ({
  sections,
  isMobile,
  activeQuestion,
  check = false,
  showSingleQuestion,
  result,
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
      }}
    >
      <Box>
        {sections.map((section, sectionIndex) => (
          <Box
            sx={{ my: 1 }}
            key={`section-${sectionIndex}`} // Unique key for section
          >
            <Typography variant="subtitle2" gutterBottom>
              {section.title}
            </Typography>
            <Grid container spacing={isMobile ? 1 : 0.5}>
              {section.questions.map((question, questionIndex) => (
                <Grid
                  item
                  xs={question.type === 'mcq' ? 4 : 6}
                  key={question.questionId}
                >
                  {activeQuestion === question ? (
                    <MainButton
                      variant="first"
                      sx={{ width: '100%', borderRadius: 4 }}
                      key={`question-${sectionIndex}-${questionIndex}`}
                      onClick={() => {
                        showSingleQuestion(question);
                      }}
                      text={formatAnswer(
                        questionIndex,
                        question,
                        result,
                        check
                      )}
                      isLoading={false}
                    />
                  ) : (
                    <Button
                      variant="outlined"
                      sx={{
                        width: '100%',
                        borderRadius: 4,
                        // show green button if correct, pink button if incorrect
                        background: check
                          ? checkCorrect(question, result) === 0
                            ? 'none'
                            : checkCorrect(question, result) === 1
                            ? alpha(theme.palette.success.main, 0.5)
                            : alpha(theme.palette.error.main, 0.5)
                          : 'none',
                      }}
                      key={`question-${sectionIndex}-${questionIndex}`} // Unique key for each question
                      onClick={() => {
                        showSingleQuestion(question);
                      }}
                    >
                      {formatAnswer(questionIndex, question, result, check)}
                    </Button>
                  )}
                </Grid>
              ))}
            </Grid>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default QuestionButtonList;
