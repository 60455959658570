import { Container, Typography } from '@mui/material';
import React, { FC } from 'react';
import MePage from './MePage';
import HomePage from '.';
import HistoryPage from './HistoryPage';
import { useLocation } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import NotFoundPage from '../Error/NotFoundPage';
import paths from '../../constants/paths';
import CheckUserPage from './CheckUsersPage';
import CheckResultsPage from './CheckResultsPage';

const MyInfoPage: FC = () => {
  const location = useLocation();
  const { user } = useAuth();

  if (location.pathname !== paths.HOME && !user) {
    return <NotFoundPage message="Trang chỉ dành cho tài khoản đăng nhập" />;
  }

  return (
    <>
      <MePage />
      {location.pathname !== paths.HOME && user && user.role !== 'student' && (
        <HomePage />
      )}
      <HistoryPage />
      {location.pathname !== paths.HOME && user && user.role === 'admin' && (
        <>
          <CheckUserPage />
          <CheckResultsPage />
        </>
      )}
    </>
  );
};

export default MyInfoPage;
