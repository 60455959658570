import React from 'react';
import { IconButton, SxProps, Theme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

interface AddButtonProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void; // Event handler for the button click
  sx?: SxProps<Theme>; // Optional sx prop to pass custom styles
}

const AddButton: React.FC<AddButtonProps> = ({ onClick, sx }) => {
  return (
    <IconButton
      sx={{
        backgroundColor: 'background.paper',
        width: {
          xs: 36,
          sm: 36,
        },
        height: {
          xs: 36,
          sm: 36,
        },
        '&:hover': {
          backgroundColor: 'primary.light',
        },
        color: 'text.secondary',
        ...sx, // Merge custom styles with default styles
      }}
      onClick={onClick}
    >
      <AddIcon />
    </IconButton>
  );
};

export default AddButton;
