import { pxToRem, responsiveFontSizes } from "../utils/getFontValue";

// ----------------------------------------------------------------------

const FONT_PRIMARY = "Montserrat, sans-serif"; //"Arial ,Quicksand, sans-serif"; // Google Font
// const FONT_SECONDARY = 'CircularStd, sans-serif'; // Local Font
const EXAM_FONT = "Times New Roman, serif";

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 900,
  h1: {
    fontFamily: "Montserrat, sans-serif", //'Raleway, sans-serif', // "Montserrat, sans-serif", //"Segoe UI, Frank Ruhl Libre,Quicksand, sans-serif",
    fontWeight: 700,
    lineHeight: 80 / 64,
    fontSize: pxToRem(40),
    letterSpacing: 1,
    ...responsiveFontSizes({ sm: 52, md: 58, lg: 64 }),
  },
  h2: {
    fontFamily: "Montserrat, sans-serif", //"Montserrat, sans-serif", // "Segoe UI, Frank Ruhl Libre,Quicksand, sans-serif",
    fontWeight: "bold",
    lineHeight: 64 / 48,
    fontSize: pxToRem(32),
    ...responsiveFontSizes({ sm: 40, md: 44, lg: 48 }),
  },
  h3: {
    fontFamily: "Montserrat, sans-serif", //"Segoe UI, Frank Ruhl Libre,Quicksand, sans-serif",
    fontWeight: 900,
    lineHeight: 1.5,
    fontSize: pxToRem(24),
    ...responsiveFontSizes({ sm: 26, md: 30, lg: 32 }),
  },
  h4: {
    fontFamily: "Montserrat, sans-serif", // "Segoe UI, Frank Ruhl Libre,Quicksand, sans-serif",
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(20),
    ...responsiveFontSizes({ sm: 20, md: 20, lg: 20 }),
  },
  h5: {
    fontFamily: "Montserrat, sans-serif", //"Segoe UI, Frank Ruhl Libre,Quicksand, sans-serif",
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(18),
    ...responsiveFontSizes({ sm: 19, md: 20, lg: 20 }),
  },
  h6: {
    fontFamily: "Montserrat, sans-serif", //Segoe UI, Frank Ruhl Libre,Quicksand, sans-serif",
    fontWeight: 700,
    lineHeight: 28 / 18,
    fontSize: pxToRem(17),
    ...responsiveFontSizes({ sm: 18, md: 18, lg: 18 }),
  },
  subtitle1: {
    fontFamily: "Montserrat, sans-serif",
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: pxToRem(14),
  },
  subtitle2: {
    fontFamily: "Montserrat, sans-serif", //"Segoe UI, Frank Ruhl Libre,Quicksand, sans-serif",
    fontWeight: 500,
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
  },
  body1: {
    lineHeight: 1.5,
    fontSize: pxToRem(16),
  },
  body2: {
    fontFamily: "Montserrat, sans-serif", // "Segoe UI, Frank Ruhl Libre,Quicksand, sans-serif",
    fontWeight: 500,
    lineHeight: 22 / 14,
    fontSize: pxToRem(16),
  },
  caption: {
    lineHeight: 1.5,
    fontSize: pxToRem(12),
  },

  // overline: {
  //   fontWeight: 700,
  //   lineHeight: 1.5,
  //   fontSize: pxToRem(12),
  //   textTransform: "uppercase",
  // },
  // button: {
  //   fontFamily: "Lato",
  //   fontWeight: 700,
  //   lineHeight: 24 / 14,
  //   fontSize: pxToRem(14),
  //   textTransform: "capitalize",
  // },
};

export default typography;
