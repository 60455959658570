import React from 'react';
import {
  alpha,
  Button,
  CircularProgress,
  SxProps,
  Theme,
  useTheme,
} from '@mui/material';
import { PersonAddAltOutlined, Person } from '@mui/icons-material';

type MainButtonProps = {
  text: string;
  isLoading: boolean;
  onClick: () => void;
  icon?: React.ReactNode;
  variant?: 'first' | 'dark';
  sx?: SxProps<Theme>;
};

const MainButton: React.FC<MainButtonProps> = ({
  isLoading,
  onClick,
  text,
  icon,
  sx,
  variant = 'first',
}) => {
  const theme = useTheme();

  // Define the gradient colors for the 'first' variant
  const gradientBackground =
    variant === 'first'
      ? 'linear-gradient(to right,' + theme.palette.prize.first + ', #ff7e5f)' // Example gradient
      : theme.palette.primary.main;

  return (
    <Button
      variant="contained"
      fullWidth
      sx={{
        borderRadius: 1,
        background: gradientBackground,
        '&:hover': {
          background:
            variant === 'first'
              ? 'linear-gradient(to right,' +
                alpha(theme.palette.prize.first, 0.7) +
                ',' +
                alpha('#ff7e5f', 0.7) +
                ')' // Example gradient
              : theme.palette.primary.dark,
        },
        ...sx,
      }}
      onClick={onClick}
      startIcon={isLoading ? <CircularProgress size={20} /> : icon}
      disabled={isLoading}
    >
      {text}
    </Button>
  );
};

export default MainButton;
