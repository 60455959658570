import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import ExamIcon from '../components/IconComponent/ExamIcon';
import OmegaIcon from '../components/IconComponent/OmegaIcon';
import HomeIcon from '@mui/icons-material/Home';
import HistoryIcon from '@mui/icons-material/History';
import ClassIcon from '@mui/icons-material/Class';
import InfoIcon from '@mui/icons-material/Info';
import ViewListIcon from '@mui/icons-material/ViewList';

import paths from './paths';
import BioIcon from '../components/IconComponent/BioIcon';
const content = {
  LOGO: 'Eduzy',
  NAV_LINK: [
    {
      text: 'Trang chủ',
      path: paths.HOME,
      icon: HomeIcon,
    },
  ],
  NAV_LINK_YOU: [
    {
      text: 'Thông tin',
      path: paths.ME,
      icon: InfoIcon,
    },
    {
      text: 'Quản lý khoá',
      path: paths.MYCOURSE,
      icon: ViewListIcon,
    },
    {
      text: 'Lịch sử làm bài',
      path: paths.HISTORY,
      icon: HistoryIcon,
    },
  ],
  NAV_AUTH: {
    login: {
      text: 'Đăng nhập',
      path: '',
      icon: ExitToAppOutlinedIcon,
    },
    register: {
      text: 'Đăng ký',
      path: '',
      icon: PersonAddOutlinedIcon,
    },
  },
  NUMBERS: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
  birthFormat: 'DD/MM/YYYY',
};

export default content;
