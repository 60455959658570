import React, { useState } from 'react';
import { Typography, Dialog, Box, SxProps } from '@mui/material';
import PropTypes from 'prop-types';

interface TruncatedTextWithDialogProps {
  content: string;
  sx?: SxProps;
  dialogContent: React.ReactNode;
}

const TruncatedTextWithDialog: React.FC<TruncatedTextWithDialogProps> = ({
  content,
  sx,
  dialogContent,
}) => {
  const [showDialog, setShowDialog] = useState(false);

  const handleOpenDialog = () => {
    setShowDialog(true);
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  return (
    <>
      <Typography
        variant="h6"
        onClick={handleOpenDialog}
        sx={{
          display: '-webkit-box',
          WebkitLineClamp: 1,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          cursor: 'pointer',
          ...sx, // Allows custom styling through props
        }}
      >
        {content}
      </Typography>
      <Dialog open={showDialog} onClose={handleCloseDialog} maxWidth="sm">
        <Box p={3}>{dialogContent}</Box>
      </Dialog>
    </>
  );
};

export default TruncatedTextWithDialog;
