import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Exam } from './type';
import { formatTime } from './CountDownToStart';

interface CountDownToResultProps {
  exam: Exam | null; // The exam object containing info about the exam
  timediff: number; // Time difference between local and server time (in seconds)
  isTeacher?: boolean;
  onShowResult: (start: boolean) => void; // Callback to indicate when to start
}

const CountDownToResult: React.FC<CountDownToResultProps> = ({
  exam,
  timediff,
  //isTeacher,
  onShowResult,
}) => {
  const [countDownToStart, setCountDownToStart] = useState(0);
  const theme = useTheme(); // Access theme

  useEffect(() => {
    if (!exam) return; // Exit early if no exam
    //if (isTeacher) onShowResult(true); // If teacher, show result immediately
    if (exam) {
      if (exam.isExercise) {
        // If it's an exercise, start immediately
        onShowResult(true);
      } else {
        if (!exam.executedAt) return; // If no start time, exit early

        // Calculate time until the exam starts
        const startAt = new Date(exam.executedAt);
        const now = new Date(Date.now() + timediff * 1000);

        const diff = startAt.getTime() - now.getTime();
        const countDown = exam.duration * 60 + Math.floor(diff / 1000);
        setCountDownToStart(countDown);

        if (countDown <= 0) {
          onShowResult(true);
        } else {
          onShowResult(false);

          // Set an interval to count down until the exam starts
          const timer = setInterval(() => {
            setCountDownToStart((prevTime) => {
              if (prevTime > 0) {
                return prevTime - 1;
              } else {
                clearInterval(timer);
                onShowResult(true);
                return 0;
              }
            });
          }, 1000);

          // Cleanup the interval when component unmounts
          return () => clearInterval(timer);
        }
      }
    }
  }, [exam, timediff, onShowResult]); //, isTeacher

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        py: 2,
      }}
    >
      <Typography
        variant="h4"
        textAlign="center"
        sx={{
          background: `linear-gradient(to right, ${theme.palette.prize.first}, #ff7e5f)`,
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent', // Transparent to show the gradient
        }}
      >
        Kết quả sẽ được công bố sau
      </Typography>
      <Typography
        variant="h2"
        textAlign="center"
        sx={{
          background: `linear-gradient(to right, ${theme.palette.prize.first}, #ff7e5f)`,
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent', // Transparent to show the gradient
        }}
      >
        {formatTime(countDownToStart)}
      </Typography>
    </Box>
  );
};

export default CountDownToResult;
