import { CssBaseline } from '@mui/material';
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider as MUIThemeProvider,
} from '@mui/material/styles';
import { useEffect, useMemo, useState } from 'react';

import palette from './palette';
import lightPalette from './lightpalette';
import typography from './typography';
import components from './components';
import { useAppSelector } from '../hooks/redux';
// use index.css or darkindex.css based on isDarkMode

const ThemeProvider: React.FC<{ children: React.ReactNode }> = (props) => {
  const isDarkMode = useAppSelector((state) => state.app.isDarkMode);
  const currentTheme = isDarkMode ? palette : lightPalette;

  const themeOptions = useMemo(
    () => ({
      palette: currentTheme,
      typography,
      components,
      shape: { borderRadius: 8 },
    }),
    [currentTheme]
  );

  const theme = createTheme(themeOptions);

  // Dynamically load CSS based on the theme
  // useEffect(() => {
  //   console.log('isDarkMode', isDarkMode);
  //   const linkElement = document.createElement('link');
  //   linkElement.rel = 'stylesheet';
  //   linkElement.href = isDarkMode ? '/darkindex.css' : '/index.css';
  //   document.head.appendChild(linkElement);

  //   // Clean up previous theme link when theme changes
  //   return () => {
  //     document.head.removeChild(linkElement);
  //   };
  // }, [isDarkMode]);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {props.children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
};

export default ThemeProvider;
