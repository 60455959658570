import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Dialog,
  Button,
  Stack,
  Typography,
  IconButton,
  InputAdornment,
  CircularProgress,
  TextField,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { MODAL, appActions } from '../../redux/slices/appSlice';
import { makeTeacher, findEmails } from '../../api'; // Import the API functions

const AddTeacherDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const { showAddTeacherModal } = useAppSelector((state) => state.app);

  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<any[]>([]); // State to store searched users
  const [selectedUser, setSelectedUser] = useState<string | null>(null); // Store the selected user's ID

  // Handle search input changes
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  // Fetch users from API based on the search term
  useEffect(() => {
    const fetchUsers = async () => {
      if (searchTerm.length > 2) {
        setLoading(true);
        try {
          const response = await findEmails(searchTerm); // Replace with your token logic
          setUsers(response.data.students); // Assuming the API returns an array of users
        } catch (error) {
          console.error('Error fetching users:', error);
        } finally {
          setLoading(false);
        }
      } else {
        setUsers([]);
      }
    };

    fetchUsers();
  }, [searchTerm]);

  const handleCloseModal = () => {
    dispatch(appActions.closeModal(MODAL.ADD_TEACHER));
  };

  const handleSelectUser = (id: string) => {
    setSelectedUser(id);
  };

  const handleSubmit = async () => {
    if (!selectedUser) return;
    setLoading(true);
    try {
      // Call the API to make the selected user a teacher
      await makeTeacher(selectedUser); // Replace with your token logic
      dispatch(
        appActions.showNotification({
          variant: 'success',
          message: 'User has been successfully assigned as a teacher.',
        })
      );
      dispatch(appActions.closeModal(MODAL.ADD_TEACHER));
    } catch (err) {
      dispatch(
        appActions.showNotification({
          variant: 'error',
          message: 'Failed to assign user as a teacher.',
        })
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={showAddTeacherModal} onClose={handleCloseModal}>
      <Box sx={{ p: 3, width: 400 }}>
        <Typography variant="h3" textAlign="center" mb={2}>
          Thêm giáo viên
        </Typography>

        {/* Search bar */}
        <TextField
          fullWidth
          placeholder="Tìm kiếm email"
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />

        {/* Users List */}
        <List sx={{ mt: 2, maxHeight: 300, overflowY: 'auto' }}>
          {loading ? (
            <CircularProgress />
          ) : users.length === 0 && searchTerm.length > 2 ? (
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ textAlign: 'center' }}
            >
              Không tìm thấy kết quả.
            </Typography>
          ) : (
            users.map((user) => (
              <ListItem
                key={user.email}
                button
                onClick={() => handleSelectUser(user.email)}
                selected={selectedUser === user.email}
              >
                <ListItemAvatar>
                  {/* <Avatar src={user.avatar}>
                    <PersonOutlinedIcon />
                  </Avatar> */}
                  <img
                    src={user.avatar}
                    alt="avatar"
                    style={{ height: 40, width: 40, borderRadius: '50%' }}
                    referrerPolicy="no-referrer"
                  />
                </ListItemAvatar>
                <ListItemText primary={user.name} secondary={user.email} />
              </ListItem>
            ))
          )}
        </List>

        {/* Submit button */}
        <Button
          variant="contained"
          fullWidth
          sx={{ mt: 3 }}
          onClick={handleSubmit}
          disabled={loading || !selectedUser} // Disable if loading or no user selected
        >
          {loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            'Xác nhận'
          )}
        </Button>

        <IconButton
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          onClick={handleCloseModal}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
    </Dialog>
  );
};

export default AddTeacherDialog;
