import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import NavLinkStyled from './style';
import { Link as RouterLink } from 'react-router-dom';
import content from '../../constants/content';
import Logo from '../Logo';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { MODAL, appActions } from '../../redux/slices/appSlice';
import useAuth from '../../hooks/useAuth';
import MESSAGE from '../../constants/message';
import { login, logout } from '../../api';
import { authActions } from '../../redux/slices/authSlice';
import { Stack } from '@mui/material';
import paths from '../../constants/paths';
import EditIcon from '@mui/icons-material/Edit';
import LogoutIcon from '@mui/icons-material/Logout';
import { ListItemIconCustom } from '../../pages/Home/Course';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import { LoginData } from '../../model/Student';
import { googleLogout } from '@react-oauth/google';
import AddIcon from '@mui/icons-material/Add';

interface Props {
  handleOpenNavMenu: () => void;
  isDesktop: boolean | null;
  isMobile: boolean | null;
}

const ResponsiveAppBar: React.FC<Props> = ({ handleOpenNavMenu, isMobile }) => {
  const dispatch = useAppDispatch();
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const [searchOpen, setSearchOpen] = React.useState(false);
  const { user } = useAuth();

  const isDarkMode = useAppSelector((state) => state.app.isDarkMode);

  const [isVisible, setIsVisible] = React.useState(true); // Controls visibility of AppBar
  // const lastScrollY = React.useRef(window.scrollY); // Store last scroll position

  // React.useEffect(() => {
  //   const handleScroll = () => {
  //     const currentScrollY = window.scrollY;

  //     // Show AppBar if scrolling up, hide if scrolling down
  //     if (currentScrollY > lastScrollY.current && currentScrollY > 100) {
  //       setIsVisible(false);
  //     } else {
  //       setIsVisible(true);
  //     }

  //     lastScrollY.current = currentScrollY;
  //   };

  //   window.addEventListener('scroll', handleScroll);

  //   // Cleanup event listener on unmount
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  const userActions = [
    {
      label: 'Cập nhật thông tin',
      icon: <EditIcon fontSize="small" />,
      handler: () => {
        dispatch(appActions.showModal(MODAL.UPDATE_PROFILE));
        setAnchorElUser(null);
      },
    },
    // {
    //   label: "Đổi mật khẩu",
    //   icon: <PasswordIcon fontSize="small" />,
    //   handler: () => {
    //     dispatch(appActions.showModal(MODAL.CHANGE_PASSWORD));
    //     setAnchorElUser(null);
    //   },
    // },
    isDarkMode
      ? {
          label: 'Chế độ sáng',
          icon: <LightModeIcon fontSize="small" />,
          handler: () => {
            dispatch(appActions.toggleDarkMode());
            setAnchorElUser(null);
          },
        }
      : {
          label: 'Chế độ tối',
          icon: <DarkModeIcon fontSize="small" />,
          handler: () => {
            dispatch(appActions.toggleDarkMode());
            setAnchorElUser(null);
          },
        },
  ];

  user?.role === 'admin' &&
    userActions.push({
      label: 'Thêm giáo viên',
      icon: <AddIcon fontSize="small" />,
      handler: () => {
        dispatch(appActions.showModal(MODAL.ADD_TEACHER));
        setAnchorElUser(null);
      },
    });

  // End Mobile

  // Auth Modal
  // End Auth modal

  // USER ACTION
  const [anchorElUserAction, setAnchorElUserAction] =
    React.useState<null | HTMLElement>(null);

  const handleOpenUserActionMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUserAction(event.currentTarget);
  };
  const handleCloseUserActionMenu = () => {
    setAnchorElUserAction(null);
  };
  // END USER ACTION

  // Logout
  const handleLogout = async () => {
    try {
      await logout();
      googleLogout();
      dispatch(authActions.logout());
      dispatch(
        appActions.showNotification({
          variant: 'success',
          message: MESSAGE.LOGOUT_SUCCESS,
        })
      );
      handleCloseUserActionMenu();
    } catch (err) {
      dispatch(
        appActions.showNotification({
          variant: 'error',
          message: MESSAGE.UNKNOWN_ERROR,
        })
      );
    }
  };

  const handleLogin = async (data: LoginData) => {
    try {
      const { data: response } = await login(data);
      dispatch(
        appActions.showNotification({
          variant: 'success',
          message: 'Đăng nhập thành công',
        })
      );
      dispatch(
        authActions.setUserWithToken({
          user: response.data.user,
          token: response.token,
          tokenExpires: response.tokenExpires,
        })
      );
      dispatch(appActions.toggleShowLoginModal());
    } catch (err) {
      dispatch(
        appActions.showNotification({
          variant: 'error',
          message: MESSAGE.WRONG_AUTH_INFO,
        })
      );
    }
  };

  const notAuthAction = (
    <Box
      sx={{
        flexGrow: 0,
        display: 'flex', // { xs: "none", md: "flex" },
        px: 1,
      }}
    >
      <GoogleLogin
        onSuccess={(credentialResponse) => {
          if (!credentialResponse.credential) {
            appActions.showNotification({
              variant: 'error',
              message: 'Đăng nhập thất bại',
            });
            googleLogout();
            return;
          }

          const decodedResponse: any = jwtDecode(credentialResponse.credential);

          handleLogin({
            name: decodedResponse.name,
            email: decodedResponse.email,
            avatar: decodedResponse.picture,
            token: credentialResponse.credential,
          } as LoginData);
        }}
        onError={() => {
          appActions.showNotification({
            variant: 'error',
            message: 'Đăng nhập thất bại',
          });
        }}
        shape="pill"
        type={isMobile ? 'icon' : 'standard'}
        theme={isDarkMode ? 'filled_black' : 'outline'}
        locale="vi"
        //useOneTap
      />
    </Box>
  );

  const isAuthContent = (
    <Box display="inline-block">
      <Stack direction="row" alignItems="center">
        <IconButton onClick={handleOpenUserActionMenu} sx={{ p: 0 }}>
          {/* <Avatar src={user?.avatar}>
            <AccountCircleIcon />
          </Avatar> */}
          <img
            src={user?.avatar}
            alt="Avatar"
            style={{ width: 40, height: 40, borderRadius: '50%' }}
            referrerPolicy="no-referrer"
          />
        </IconButton>
        <Menu
          sx={{ mt: '30px' }}
          id="menu-appbar"
          anchorEl={anchorElUserAction}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElUserAction)}
          onClose={handleCloseUserActionMenu}
        >
          {userActions.map((action, index) => (
            <MenuItem key={index} onClick={action.handler}>
              <ListItemIconCustom>{action.icon}</ListItemIconCustom>
              <Typography variant="subtitle2" textAlign="center">
                {action.label}
              </Typography>
            </MenuItem>
          ))}
          <MenuItem onClick={handleLogout}>
            <ListItemIconCustom>
              <LogoutIcon fontSize="small" />
            </ListItemIconCustom>
            <Typography variant="subtitle2" textAlign="center">
              Đăng xuất
            </Typography>
          </MenuItem>
        </Menu>
      </Stack>
    </Box>
  );

  return (
    <AppBar
      // position={isMobile ? 'fixed' : 'static'}
      position="static"
      sx={{
        backgroundColor: (theme) => theme.palette.background.default,
        color: (theme) => theme.palette.lighter.main,
        // transition: 'transform 0.3s ease-in-out',
        // transform: isVisible ? 'translateY(0)' : 'translateY(-100%)', // Slide effect
        top: 0,
        zIndex: 1200,
      }}
    >
      <Container
        maxWidth={false}
        sx={{
          paddingX: {
            xs: 1,
          },
        }}
      >
        <Toolbar
          disableGutters
          sx={{
            minHeight: '50px !important',
          }}
        >
          {!searchOpen && (
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
              }}
            >
              {!isMobile && (
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  sx={{
                    color: (theme) => theme.palette.lighter.main,
                    opacity: 0.6,
                    '&:hover': {
                      opacity: 1,
                    },
                  }}
                >
                  <MenuIcon />
                </IconButton>
              )}
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <NavLinkStyled
                  sx={{
                    mr: 2,
                    flexGrow: 1,
                    color: 'inherit',
                    fontSize: 23,
                    fontWeight: 500,
                    fontFamily: 'Freeman, sans-serif', // 'Oswald, sans-serif', //'Cormorant SC, serif', //'Oswald, sans-serif', //'_MontserratBold',
                    mx: 0.5,
                    opacity: 1,
                  }}
                  component={RouterLink}
                  to={paths.HOME}
                >
                  <Logo />
                  {content.LOGO}
                </NavLinkStyled>
              </Stack>
            </Box>
          )}

          {/* <SearchBar searchOpen={searchOpen} setSearchOpen={setSearchOpen} /> */}
          {/* {!searchOpen && <ToggleThemeButton />} */}

          {user ? isAuthContent : notAuthAction}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
