import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CourseProps } from '../../pages/Home/Course';
import { add } from 'date-fns';

interface CourseState {
  courses: CourseProps[];
}

const initialState: CourseState = {
  courses: [],
};

const courseSlice = createSlice({
  name: 'course',
  initialState,
  reducers: {
    setCourses(state, action: PayloadAction<CourseProps[]>) {
      state.courses = action.payload;
    },
    addCourseAction(state, action: PayloadAction<CourseProps>) {
      state.courses.push(action.payload);
    },
    updateCourse(state, action: PayloadAction<CourseProps>) {
      const index = state.courses.findIndex(
        (course) => course.courseId === action.payload.courseId
      );
      if (index !== -1) {
        state.courses[index] = action.payload;
      } else {
        state.courses.push(action.payload); // Add new course if not found
      }
    },
    deleteCourse(state, action: PayloadAction<string>) {
      state.courses = state.courses.filter(
        (course) => course.courseId !== action.payload
      );
    },
  },
});

export const { setCourses, updateCourse, deleteCourse, addCourseAction } =
  courseSlice.actions;
export default courseSlice;
